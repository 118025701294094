import { IAppState } from 'vev';
import { GLOBAL_EVENT_TYPES, ProjectInteraction, hasLink, hasPointerEvent } from '@vev/utils';
import { pagePathByKey } from './route';

export function getInteractionsNodeWithAttributes(
  interactions: ProjectInteraction[],
  state: IAppState,
  defaultTag?: string,
): [string, { [attr: string]: any }] {
  if (!interactions) return [defaultTag || 'div', {}];

  const isButton = interactions?.some(hasPointerEvent);
  const isLink = interactions?.some(hasLink);
  const hasTarget = interactions?.some((i) => i.event?.contentKey);

  if (!hasTarget) {
    if (defaultTag) return [defaultTag, {}];
    return ['div', {}];
  }

  // For links, we also need to get the target
  if (isLink) {
    const linkEvent = interactions?.find((i) => hasLink(i))?.event;
    const args = (linkEvent?.args || {}) as any;

    // Handle phone links
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.CALL_PHONE) {
      return ['a', { href: `tel:${args.number}` }];
    }

    // Handle sms
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.SEND_SMS) {
      return ['a', { href: `sms:${args.number}` }];
    }

    // Handle email
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.SEND_EMAIL) {
      return [
        'a',
        { href: `mailto:${args.email}${args.subject ? `?subject=${args.subject}` : ''}` },
      ];
    }

    // Handle file download
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.DOWNLOAD_FILE) {
      const linkArgs = { href: args.file?.url } as { [attr: string]: any };

      if (args.fileName) linkArgs.download = args.fileName;
      if (args?.action === 'newTab') linkArgs.target = '_blank';
      if (args?.action === 'newTab') linkArgs.rel = 'noopener noreferrer';
      return ['a', linkArgs];
    }

    // Handle external links
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.OPEN_URL) {
      const linkArgs = { href: args.url, target: args.newTab ? '_blank' : '_self' } as {
        [attr: string]: any;
      };

      const relArgs = [];
      if (args.noFollow) relArgs.push('nofollow');
      if (args.sponsored) relArgs.push('sponsored');
      if (args.safelink) relArgs.push('noopener noreferrer');
      if (relArgs.length) linkArgs.rel = relArgs.join(' ');
      return ['a', linkArgs];
    }

    // Handle page links
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.OPEN_PAGE) {
      const url = pagePathByKey(linkEvent?.contentKey || '', state.pages, state.dir);
      const linkArgs = { href: url, target: args.newTab ? '_blank' : '_self' } as {
        [attr: string]: any;
      };

      const relArgs = [];
      if (args.noFollow) relArgs.push('nofollow');
      if (args.sponsored) relArgs.push('sponsored');
      if (args.safelink) relArgs.push('noopener noreferrer');
      if (relArgs.length) linkArgs.rel = relArgs.join(' ');
      return ['a', linkArgs];
    }

    // Handle scroll-to-element links
    if (linkEvent?.type === GLOBAL_EVENT_TYPES.SCROLL_TO_ELEMENT) {
      const linkArgs = { href: `#${linkEvent?.contentKey}` } as { [attr: string]: any };
      return ['a', linkArgs];
    }

    return ['a', { href: '#' }];
  }

  if (defaultTag) return [defaultTag, {}];
  if (isButton) return ['div', { tabIndex: 0, role: 'button' }];
  return ['div', {}];
}
