import { VevProps } from '.';

export type VevCondition = string;

export enum TRIGGER_SCOPE {
  POINTER_EVENT = 'pointer-event',
  SCROLL = 'scroll',
  GLOBAL = 'global',
  KEYBOARD = 'keyboard',
}

export enum EVENT_SCOPE {
  ELEMENT = 'element',
  PAGE = 'page',
  VARIABLE = 'variable',
  EXTERNAL = 'external',
  TRACKING = 'tracking',
}

export type VevDispatchEvent = {
  type?: string;
  contentKey?: string;
  instanceKeyChain?: string;
  args?: Record<string, string>;
};

export type TriggerItem = {
  label: string;
  value: VevTriggerType;
  icon?: string;
  scope?: EVENT_SCOPE | TRIGGER_SCOPE;
};

// Native DOM triggers that are dispatched locally
export enum NATIVE_TRIGGER_TYPES {
  ON_CLICK = 'onClick',
  ON_MOUSE_ENTER = 'onMouseEnter',
  ON_MOUSE_LEAVE = 'onMouseLeave',
  ON_MOUSE_DOWN = 'onMouseDown',
  ON_MOUSE_UP = 'onMouseUp',
  ON_DOUBLE_CLICK = 'onDoubleClick',
  ON_TOUCH_START = 'onTouchStart',
  ON_TOUCH_END = 'onTouchEnd',
}

export const NATIVE_TRIGGERS: TriggerItem[] = [
  {
    label: 'On click',
    value: NATIVE_TRIGGER_TYPES.ON_CLICK,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse down',
    value: NATIVE_TRIGGER_TYPES.ON_MOUSE_DOWN,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse up',
    value: NATIVE_TRIGGER_TYPES.ON_MOUSE_UP,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse enter',
    value: NATIVE_TRIGGER_TYPES.ON_MOUSE_ENTER,
    icon: 'interaction.mouse-enter',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse leave',
    value: NATIVE_TRIGGER_TYPES.ON_MOUSE_LEAVE,
    icon: 'interaction.mouse-leave',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On double click',
    value: NATIVE_TRIGGER_TYPES.ON_DOUBLE_CLICK,
    icon: 'interaction.double-click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On touch start',
    value: NATIVE_TRIGGER_TYPES.ON_TOUCH_START,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On touch end',
    value: NATIVE_TRIGGER_TYPES.ON_TOUCH_END,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
];

// Custom triggers that are dispatched locally
export enum CUSTOM_TRIGGER_TYPES {
  ON_VISIBLE = 'onVisible',
  ON_LEAVE = 'onLeave',
  ON_SWIPE = 'onSwipe',
}

export const CUSTOM_TRIGGERS: TriggerItem[] = [
  {
    label: 'Upon entering view',
    value: CUSTOM_TRIGGER_TYPES.ON_VISIBLE,
    icon: 'eye',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'Upon leaving view',
    value: CUSTOM_TRIGGER_TYPES.ON_LEAVE,
    icon: 'eye.off',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'On swipe',
    value: CUSTOM_TRIGGER_TYPES.ON_SWIPE,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
];

// Trigger that are dispatched globally
export enum GLOBAL_TRIGGER_TYPES {
  ON_SCROLL = 'onScroll',
  ON_TIMER = 'onTimer',
  ON_KEY_DOWN = 'onKeyDown',
  ON_KEY_UP = 'onKeyUp',
}

export const GLOBAL_TRIGGERS: TriggerItem[] = [
  {
    label: 'On scroll',
    value: GLOBAL_TRIGGER_TYPES.ON_SCROLL,
    icon: 'interaction.mouse',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'On timer',
    value: GLOBAL_TRIGGER_TYPES.ON_TIMER,
    icon: 'interaction.timer',
    scope: TRIGGER_SCOPE.GLOBAL,
  },
  {
    label: 'On key down (key pressed)',
    value: GLOBAL_TRIGGER_TYPES.ON_KEY_DOWN,
    icon: 'keyboard.command',
    scope: TRIGGER_SCOPE.KEYBOARD,
  },
  {
    label: 'On key up (key released)',
    value: GLOBAL_TRIGGER_TYPES.ON_KEY_UP,
    icon: 'keyboard.command',
    scope: TRIGGER_SCOPE.KEYBOARD,
  },
];

// Global events that are dispatched
export enum GLOBAL_EVENT_TYPES {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  STICK = 'STICK',
  UNSTICK = 'UNSTICK',
  TOGGLE = 'TOGGLE_VISIBILITY',
  SCROLL_TO_ELEMENT = 'SCROLL_TO_ELEMENT',
  OPEN_PAGE = 'OPEN_PAGE',
  OPEN_URL = 'OPEN_URL',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  SEND_EMAIL = 'SEND_EMAIL',
  CALL_PHONE = 'CALL_PHONE',
  SEND_SMS = 'SEND_SMS',
  TRACK = 'TRACK',
  SET_VARIABLE = 'SET_VARIABLE',
}

export const GLOBAL_EVENTS: TriggerItem[] = [
  { label: 'Show', value: GLOBAL_EVENT_TYPES.SHOW, scope: EVENT_SCOPE.ELEMENT },
  { label: 'Hide', value: GLOBAL_EVENT_TYPES.HIDE, scope: EVENT_SCOPE.ELEMENT },
  { label: 'Stick', value: GLOBAL_EVENT_TYPES.STICK, scope: EVENT_SCOPE.ELEMENT },
  { label: 'Unstick', value: GLOBAL_EVENT_TYPES.UNSTICK, scope: EVENT_SCOPE.ELEMENT },
  { label: 'Toggle visibility', value: GLOBAL_EVENT_TYPES.TOGGLE, scope: EVENT_SCOPE.ELEMENT },
  {
    label: 'Scroll to element',
    value: GLOBAL_EVENT_TYPES.SCROLL_TO_ELEMENT,
    scope: EVENT_SCOPE.ELEMENT,
  },
  {
    label: 'Open page',
    value: GLOBAL_EVENT_TYPES.OPEN_PAGE,
    icon: 'link',
    scope: EVENT_SCOPE.PAGE,
  },
  {
    label: 'Go to URL',
    value: GLOBAL_EVENT_TYPES.OPEN_URL,
    icon: 'link.external',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Send email',
    value: GLOBAL_EVENT_TYPES.SEND_EMAIL,
    icon: 'email',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Call phone number',
    value: GLOBAL_EVENT_TYPES.CALL_PHONE,
    icon: 'mobile',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Download file',
    value: GLOBAL_EVENT_TYPES.DOWNLOAD_FILE,
    icon: 'file',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Send SMS',
    value: GLOBAL_EVENT_TYPES.SEND_SMS,
    icon: 'comment',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Track event',
    value: GLOBAL_EVENT_TYPES.TRACK,
    icon: 'trackingEvent',
    scope: EVENT_SCOPE.TRACKING,
  },
  {
    label: 'Set variable',
    value: GLOBAL_EVENT_TYPES.SET_VARIABLE,
    icon: 'variable.icon',
    scope: EVENT_SCOPE.VARIABLE,
  },
];

export type VevTriggerType = NATIVE_TRIGGER_TYPES | CUSTOM_TRIGGER_TYPES | string;

export type VevTrigger = {
  type: VevTriggerType;
  contentKey?: string;
  name?: string;
  condition?: VevCondition;
  args?: VevProps[];
};

export type ProjectInteraction = {
  key: string;
  event?: VevDispatchEvent;
  trigger?: VevTrigger;
  disabled?: boolean;
  updated: number;
  deleted?: number;
};

export type InteractionMap = {
  trigger: {
    global: Record<GLOBAL_TRIGGER_TYPES, ProjectInteraction[]>;
    widget: Record<string, ProjectInteraction[]>;
  };
  event: {
    widget: Record<string, ProjectInteraction[]>;
  };
};
