import { VevDispatchEvent } from '@vev/utils';

export function dispatchVevEvent(event: VevDispatchEvent) {
  window.dispatchEvent(
    new CustomEvent('@@vev', {
      detail: event,
    }),
  );
}

export function dispatchExternalTrackingEvent(data: string | { [key: string]: string } | any = {}) {
  const { event } = data;
  if (!event) return;

  window.dispatchEvent(
    new CustomEvent('vev.track', {
      detail: {
        type: event?.type,
        data: (event.data || []).reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr?.data?.key]: curr?.data?.value,
          }),
          {},
        ),
      },
    }),
  );
}
