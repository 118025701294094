import { useEffect, useState } from 'react';
import { IObserverOptions, IVisibleOptions } from 'vev';
import Intersection from '../../utils/intersection';

/**
 * `useIntersection` advanced alternative for useVisible to detect the intersection % of a html ref element
 * Possible to pass options to offset the viewport size and how many intersection steps it should do
 * Pass false or undefined as ref to disable
 */
export function useIntersection<T extends Element>(
  ref: false | undefined | React.RefObject<T>,
  options?: IObserverOptions,
): false | IntersectionObserverEntry {
  const [visible, setVisible] = useState<IntersectionObserverEntry | false>(false);
  useEffect(() => {
    if (!ref) return;
    const el = ref.current;
    if (el) return Intersection.add(el, (e) => setVisible(e), options);
  }, [options?.offsetBottom, options?.offsetTop]);

  return visible;
}

/**
 * `useVisible` detects when the html ref element is in viewport
 * Possible to pass options to offset the viewport size
 */
export function useVisible<T extends Element>(
  ref: false | undefined | React.RefObject<T>,
  options?: IVisibleOptions,
): boolean {
  const intersection = useIntersection(ref, options);
  return intersection && intersection.isIntersecting;
}
