import { Variable } from './types';

declare global {
  interface Window {
    variable: VariableManagerImpl;
    variable_dbg: boolean;
  }
}

let instance: VariableManagerImpl;

class VariableManagerImpl {
  private variables: Map<string, Variable> = new Map();
  private subscriptions: Map<string, ((change: Variable) => void)[]> = new Map();
  constructor() {
    this.log('Initialized VariableManager');
  }
  initializeVariables(initialVariables: Variable[]): void {
    this.log('Initialize Variables', initialVariables);
    this.variables = new Map();
    initialVariables.forEach((variable) => {
      this.setVariable(variable.key, variable);
    });
  }

  resetSubscriptions() {
    this.log('Reset subscriptions');
    this.subscriptions = new Map();
  }

  setVariable(key: string, variable: Partial<Variable>) {
    this.log('Set variable', variable);
    const prev = this.variables.get(key);
    if (prev) {
      const update = { ...prev, ...variable };
      this.variables.set(key, update);
      this.dispatchChange(update);
    } else {
      this.variables.set(key, variable as Variable);
      this.dispatchChange(variable as Variable);
    }
  }

  getVariable(key: string) {
    return this.variables.get(key);
  }

  subscribe(keys: string[], cb: (change: Variable) => void) {
    this.log('New subscription', keys);
    keys.forEach((key) => {
      const subscription = this.subscriptions.get(key);
      if (!subscription) {
        this.subscriptions.set(key, [cb]);
      } else {
        subscription.push(cb);
      }
    });
  }

  unsubscribe(keys: string[], cb: (change: Variable) => void) {
    keys.forEach((key) => {
      const subscription = this.subscriptions.get(key);
      if (subscription) {
        const filteredSubs = subscription.filter((sub) => {
          return sub !== cb;
        });
        if (filteredSubs.length) {
          this.subscriptions.set(key, filteredSubs);
        } else {
          this.subscriptions.delete(key);
        }
      }
    });
  }

  private dispatchChange(update: Variable) {
    const subscriptions = this.subscriptions.get(update.key);
    if (subscriptions) {
      subscriptions.forEach((subscription) => {
        this.log('Dispatch subscription change', update);
        subscription(update);
      });
    }

    if (update.type !== 'text') {
      document.documentElement.style.setProperty(`--vev-${update.key}`, update.value);
    }
  }

  private log(...data: any[]) {
    if (window.variable_dbg) {
      console.log('🟢 VariableManager | ', ...data);
    }
  }
}

export const VariableManager = {
  getInstance() {
    if (!instance) {
      window.variable_dbg = false;
      instance = new VariableManagerImpl();
      window.variable = instance;
    }
    return instance;
  },
};
