import { DependencyList, useEffect, useRef } from 'react';
import { useCallbackRef } from './use-callback-ref';

const CLICK_LISTENERS: ((e: MouseEvent) => void)[] = [];
let listening = false;

function emit(e: MouseEvent) {
  for (const cb of CLICK_LISTENERS) cb(e);
}

function onBodyClick(cb: (e: MouseEvent) => void) {
  if (!listening) {
    listening = true;
    document.addEventListener('click', emit, { passive: true });
  }

  return () => {
    const index = CLICK_LISTENERS.indexOf(cb);
    if (index !== -1) CLICK_LISTENERS.splice(index, 1);
  };
}

/**
 * `useOutsideClick` watches for clicks outside a given ref and calls the callback when it happens
 */
export function useOutsideClick(callback: (event: MouseEvent) => void, deps?: DependencyList) {
  const ref = useRef<Element>();
  const cbRef = useCallbackRef(callback);

  useEffect(() => {
    return onBodyClick((event: MouseEvent) => {
      const el = ref.current;
      if (el && !el.contains(event.target as Node)) cbRef.current(event);
    });
  }, deps);

  return ref;
}
