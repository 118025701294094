import { useEffect, useState } from 'react';
import { useCallbackRef } from './use-callback-ref';

const isTouchDevice = () => 'ontouchstart' in window;

function getDimensionsFromEvent(ref: React.RefObject<HTMLElement> | null, event: MouseEvent) {
  let x = event.clientX,
    y = event.clientY;

  if (ref?.current) {
    const { top, left } = ref.current.getBoundingClientRect();
    x -= left;
    y -= top;
  }

  return { x, y };
}

function getSwipeDir(
  [startX, startY]: [number, number],
  [x, y]: [number, number],
): 'left' | 'right' | 'up' | 'down' | false {
  const dx = x - startX;
  const dy = y - startY;
  const absDx = Math.abs(dx);
  const absDy = Math.abs(dy);

  if (absDx > 30 || absDy > 30) {
    if (absDx > absDy) {
      return dx > 0 ? 'right' : 'left';
    }
    return dy > 0 ? 'down' : 'up';
  }

  return false;
}

export function useSwipe(
  ref: React.RefObject<HTMLElement> | null,
  enabled: boolean,
  onSwipe: (direction: 'left' | 'right' | 'up' | 'down', e: MouseEvent | TouchEvent) => void,
) {
  const [startPos, setStartPos] = useState<[number, number]>([0, 0]);
  const onSwipeRef = useCallbackRef(onSwipe);

  function handleTouchStart(event: TouchEvent) {
    setStartPos([event.changedTouches[0].clientX, event.changedTouches[0].clientY]);
  }

  function handleTouchEnd(event: TouchEvent) {
    if (event.changedTouches && event.changedTouches.length > 0) {
      const direction = getSwipeDir(startPos, [
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY,
      ]);
      direction && onSwipeRef.current?.(direction, event);
    }

    setStartPos([0, 0]);
  }

  function handleMouseDown(event: MouseEvent) {
    const { x, y } = getDimensionsFromEvent(ref, event);
    setStartPos([x, y]);
  }

  function handleMouseUp(event: MouseEvent) {
    const { x, y } = getDimensionsFromEvent(ref, event);
    const direction = getSwipeDir(startPos, [x, y]);
    direction && onSwipeRef.current?.(direction, event);
    setStartPos([0, 0]);
  }

  useEffect(() => {
    const currentElement = ref?.current;

    if (currentElement && enabled) {
      if (isTouchDevice()) {
        currentElement.addEventListener('touchstart', handleTouchStart);
        currentElement.addEventListener('touchend', handleTouchEnd);
      } else {
        currentElement.addEventListener('mousedown', handleMouseDown);
        currentElement.addEventListener('mouseup', handleMouseUp);
      }
    }

    return () => {
      if (currentElement && enabled) {
        if (isTouchDevice()) {
          currentElement.removeEventListener('touchstart', handleTouchStart);
          currentElement.removeEventListener('touchend', handleTouchEnd);
        } else {
          currentElement.removeEventListener('mousedown', handleMouseDown);
          currentElement.removeEventListener('mouseup', handleMouseUp);
        }
      }
    };
  }, [ref, onSwipeRef, enabled, startPos]);
}
