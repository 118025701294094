import { InteractionMap, ProjectInteraction } from '../types';
import { isGlobalTrigger } from './get-interaction-map';

export function interactionMapToArray(interactions: InteractionMap): ProjectInteraction[] {
  if (!interactions) return [];
  const result: ProjectInteraction[] = [];

  if (interactions.event?.widget) {
    for (const key of Object.keys(interactions.event.widget)) {
      interactions.event.widget[key] && result.push(...interactions.event.widget[key]);
    }
  }

  if (interactions.trigger?.widget) {
    for (const key of Object.keys(interactions.trigger.widget)) {
      interactions.event.widget[key] && result.push(...interactions.event.widget[key]);
    }
  }

  if (interactions.trigger?.global) {
    for (const key of Object.keys(interactions.trigger.global)) {
      if (isGlobalTrigger(key)) {
        interactions.trigger.global[key] && result.push(...interactions.trigger.global[key]);
      }
    }
  }

  return result;
}
