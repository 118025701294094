import React, { forwardRef } from 'react';
import { ImageProps } from 'vev';
import { isString } from '../../utils';
import { useImage, useIsIndexPage } from '../../core/hooks';
import { stringifyEntities } from 'stringify-entities';

type ImageSize = [number, number];

function clean(str: string): string {
  const removeLineBreaks = (str = '') => str.replace(/\n/g, '');
  return stringifyEntities(removeLineBreaks(str || ''));
}

function getSize([deviceSize, resSize]: ImageSize) {
  return `(max-width: ${deviceSize}px) ${resSize}px`;
}

function sortByDevice(s1: ImageSize, s2: ImageSize) {
  return s1[0] - s2[0];
}

const Image = forwardRef(({ src, sizes: modeSizes, style, className }: ImageProps, ref) => {
  const img = useImage(src ? (src as any).key || src : '');
  const isIndexPage = useIsIndexPage();

  function replaceDotDotSlash(str: string): string {
    return str.replace(new RegExp('\\.\\.\\/', 'g'), '/');
  }

  let srcSet: any, sizes: any, alt: any;
  if (img) {
    // console.log(img)
    if (modeSizes) {
      modeSizes.sort(sortByDevice);
      sizes = '';
      const len = modeSizes.length - 1;
      for (let i = 0; i < len; i++) sizes += getSize(modeSizes[i]) + ' ';
      sizes += modeSizes[len][1] + 'px';
    }

    srcSet = img.srcset.map((imgSize) => `${imgSize[0]} ${imgSize[1]}w`).join(',');
    src = img.src || (img as any).url;

    if (isIndexPage) {
      srcSet = replaceDotDotSlash(srcSet);
    }

    if (!src && img.srcset[img.srcset.length - 1]) src = img.srcset[img.srcset.length - 1][0];
    alt = img.meta && img.meta.description;
  }

  if (isString(src) && /^\/\//.test(src)) src = 'https:' + src;

  const replacedSrc = isIndexPage && isString(src) ? replaceDotDotSlash(src) : src;

  return (
    <img
      style={style}
      loading="lazy"
      className={className}
      src={isString(replacedSrc) ? replacedSrc : ''}
      srcSet={srcSet}
      sizes={sizes}
      alt={clean(alt)}
      ref={ref as any}
    />
  );
});

Image.displayName = 'Image';
export default Image;
