import { GLOBAL_TRIGGER_TYPES, InteractionMap, ProjectInteraction } from '../types';

export function getInteractionMap(interactions: ProjectInteraction[] = []): InteractionMap {
  const result: InteractionMap = {
    trigger: {
      global: {
        onScroll: [],
        onTimer: [],
        onKeyDown: [],
        onKeyUp: [],
      },
      widget: {},
    },
    event: {
      widget: {},
    },
  };

  interactions.forEach((interaction) => {
    if (interaction.trigger?.type && isGlobalTrigger(interaction.trigger?.type)) {
      const globalElement = result.trigger.global[interaction.trigger?.type];
      globalElement.push(interaction);
    } else {
      createOrUpdateArray(result.trigger.widget, interaction.trigger?.contentKey, interaction);
    }

    createOrUpdateArray(result.event.widget, interaction.event?.contentKey, interaction);
  });

  return result;
}

export function isGlobalTrigger(value: string): value is GLOBAL_TRIGGER_TYPES {
  return ['onScroll', 'onTimer', 'onKeyDown', 'onKeyUp'].includes(value);
}

function createOrUpdateArray(
  map: Record<string, ProjectInteraction[]>,
  key: string | undefined,
  interaction: ProjectInteraction,
) {
  if (!key) return;
  if (!Array.isArray(map[key])) {
    map[key] = [];
  }

  map[key].push(interaction);
}
