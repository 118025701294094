import { NATIVE_TRIGGERS, ProjectInteraction, TRIGGER_SCOPE } from '../types';

/**
 * Checks for interaction trigger DOM nodes
 */
export function hasPointerEvent(interaction: Partial<ProjectInteraction>): boolean {
  const triggerMatch = NATIVE_TRIGGERS.find(
    (trigger) => trigger.value === interaction.trigger?.type,
  );

  return triggerMatch?.scope === TRIGGER_SCOPE.POINTER_EVENT;
}
