import { useEffect } from 'react';
import { useCallbackRef } from './use-callback-ref';

/**
 * `useInterval` simple way of starting a set interval in component
 */
export function useInterval(callback: () => any, delay: number) {
  const savedCallback = useCallbackRef(callback);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
