import { useEffect, useRef, useState } from 'react';

type ResizeCallback = (size: { width: number; height: number }) => void;
const OBSERVING = new Map<Element, ResizeCallback[]>();
// It's more performance to use 1 resize observer according to WICG https://github.com/WICG/resize-observer/issues/59#issuecomment-408098151
let observer: ResizeObserver;

function watchElementSize(el: Element, cb: ResizeCallback) {
  if (!observer) {
    observer = new ResizeObserver((entries) => {
      for (const { target, contentRect } of entries) {
        const size = { width: contentRect.width, height: contentRect.height };
        const cbs = OBSERVING.get(target);
        if (cbs) {
          for (const cb of cbs) cb(size);
        }
      }
    });
  }
  const val = OBSERVING.get(el) || [];
  // If no watchers then start observing
  if (!val.length) observer.observe(el);

  val.push(cb);
  OBSERVING.set(el, val);
  return () => {
    const val = OBSERVING.get(el) || [];
    if (val.length === 1) {
      OBSERVING.delete(el);
      observer.unobserve(el);
    } else {
      OBSERVING.set(
        el,
        val.filter((existingCb) => existingCb !== cb),
      );
    }
  };
}

/**
 * `useSize` watches and returns the size of a html ref element,
 */
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
): { width: number; height: number };
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
  onChange: (size: { width: number; height: number }) => void,
): void;
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
  onChange?: (size: { width: number; height: number }) => void,
): { width: number; height: number } | void {
  const [size, setSize] = useState<{ width: number; height: number }>(() => {
    const el = ref.current;
    return { width: el ? el.clientWidth : 0, height: el ? el.clientHeight : 0 };
  });
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const el = ref.current;
    if (el) {
      return watchElementSize(el, (size) => {
        const onChange = onChangeRef.current;
        if (onChange) onChange?.(size);
        else setSize(size);
      });
    }
  }, [ref.current]);

  if (onChange) return;
  return size;
}
