import { DependencyList, useLayoutEffect } from 'react';
import { raf } from '../../utils/animation';

/**
 * `useFrame` can by use for animation or style changes.
 *  Use with caution as this will trigger the callback every animation frame
 *  Pass false as first argument to disabled.
 */
export function useFrame(
  callback: false | ((timestamp?: number) => void),
  deps?: DependencyList,
): void {
  useLayoutEffect(() => {
    if (callback === false) return;
    callback(performance.now());
    return raf(callback, true);
  }, deps);
}
