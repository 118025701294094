import { EVENT_SCOPE, GLOBAL_EVENTS, GLOBAL_EVENT_TYPES, ProjectInteraction } from '../types';

/**
 * Checks for interaction trigger DOM nodes
 */
export function hasLink(interaction: Partial<ProjectInteraction>): boolean {
  const eventMatch = GLOBAL_EVENTS.find((event) => event.value === interaction.event?.type);

  return (
    eventMatch?.scope === EVENT_SCOPE.EXTERNAL ||
    eventMatch?.scope === EVENT_SCOPE.PAGE ||
    eventMatch?.value === GLOBAL_EVENT_TYPES.SCROLL_TO_ELEMENT
  );
}
